.workPage{
    background: linear-gradient(180deg, #2a4a67, #1e282f 100%);
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-left: 60px;
    font-family: 'Roboto';
    font-weight: 700;

    overflow-y: scroll;
    overflow-x: hidden;
    @media screen and (max-width: 800px){
        margin-left:0;
        margin-top: 60px;
    }
}

.mywork{
    color: #7be7e9;
    @media screen and (max-width: 500px){
        margin-bottom: 20px;
    }
}