.contactPage{
    background: linear-gradient(180deg, #2a4a67, #1e282f 100%);
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-left: 60px;
    font-family: 'Roboto';
    font-weight: 700;
    display: grid;
    grid-template-columns: auto auto auto;


    @media screen and (max-width: 800px){
        margin-left:0;
        margin-top: 60px;
    }

    .contactLeft{
        margin-left:60px; 
        margin-top: 30vh;
        
        font-family: 'Roboto';
        font-weight: 400;
        color: #DEDEDE;
        margin-right: 10vw;
        grid-column-start: 1;
        grid-column-end: 3;
        
        @media screen and (max-width: 500px){
            margin-top: 10vh;
        }

        .contactMe{
            width: 35vw;
            float:left;
            font-size: 40px;
            @media screen and (max-width: 850px){
                width: 100%;
            }
        }

        .linkIcon{
            margin-top: 5vh;
            width: 70px;
            height: 70px;
        }
        @media screen and (max-width: 850px){
            
            grid-column-start: 1;
            grid-column-end: 4;
            margin-right:10vw;
        }
    }

    .contactRight{
        margin-top: 30vh;
        margin-bottom: 10vh;
        width: 50vw;
        @media screen and (max-width: 850px){
            visibility: hidden;
        }
        overflow: hidden;
    }
}