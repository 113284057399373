.homePage{
    background: linear-gradient(180deg, #2a4a67, #1e282f 100%);
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-left: 60px;
    font-family: 'Roboto';
    font-weight: 700;

    @media screen and (max-width: 800px){
        margin-left:0;
        margin-top: 60px;
    }

        .hi{
            margin-top: 35vh;
            width: 50vw;
            float:left;
            font-size: 45px;
            margin-bottom: 10px;
            margin-left:60px; 
            transition: all 0.3s ease;
            @media screen and (max-width: 500px){
                margin-top: 25vh;
            }
        }
        .Iam{
            margin-top: 5px;
            width: 50vw;
            float:left;
            font-size: 45px;
            margin-bottom: 10px;
            margin-left:60px; 
            @media screen and (max-width: 800px){
                width: 70vw;
            }
        }
    
    .intro{
        width: 80vw;
        font-size: 45px;
        margin-left: 60px;
        color:transparent;
        text-shadow: 0 0 0 #4ef0f4;
        // transition: opacity 1s ease-out;
        // opacity: 0;
        // height:0;
        // overflow: hidden;
        @media screen and (max-width: 1200px){
            width: 50vw;
        }
        @media screen and (max-width: 800px){
            width: 80vw;
        }
    }

    .cartoonMe{
        position: absolute;
        height: 525px;
        width: 400px;
        margin-left: 60vw;
        margin-top:  25vh;
        @media screen and (max-width: 800px){
            display: none;
        }
    }
}

@-webkit-keyframes hi {
    15% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
    30% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
    50% {
        -webkit-transform: translateY(3px);
        transform: translateY(3px);
    }
    65% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px);
    }
    80% {
        -webkit-transform: translateY(2px);
        transform: translateY(2px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes hi {
    15% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
    30% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
    50% {
        -webkit-transform: translateY(3px);
        transform: translateY(3px);
    }
    65% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px);
    }
    80% {
        -webkit-transform: translateY(2px);
        transform: translateY(2px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
.hi:hover
{
    -webkit-animation: hi 1s ease;
    animation: hi 1s ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}