.aboutPage{
    background: linear-gradient(180deg, #2a4a67, #1e282f 100%);
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-left: 60px;
    font-family: 'Roboto';
    font-weight: 700;
    display: grid;
    grid-template-columns: auto auto auto;

    @media screen and (max-width: 800px){
        margin-left:0;
        margin-top: 60px;
    }

    .aboutLeft{
        grid-column-start: 1;
        grid-column-end: 3;
        margin-left: 80px;
        margin-top: 30vh;
        font-family: 'Roboto';
        font-weight: 500;
        color: #DEDEDE;
        margin-right:10vw;

        .about{
            font-size: 40px;
            margin: auto;
        }
        @media screen and (max-width: 900px){
            grid-column-end: 4;
            margin-right: 10vw;
            margin-top: 20vh;
        }
        @media screen and (max-width: 500px){
            margin-top: 10vh;
        }

    }
    .aboutRight{
        margin-top: 35vh;
        margin-bottom: 10vh;
        width: 40vw;
        @media screen and (max-width: 900px){
            visibility: hidden;
        }
    }
}