h1 span span {
    color:transparent;
    text-shadow:0 0 0#c9fafb;
    position: relative;
    bottom: -1em;
    opacity: 0;
    animation: move-text 0.75s forwards;
  }
  h1 span span:nth-child(2n) { text-shadow: 0 0 0 #b8f9fa; }
  h1 span span:nth-child(3n) { text-shadow: 0 0 0 #a6f7f9; }
  h1 span span:nth-child(4n) { text-shadow: 0 0 0 #94f6f8; }
  h1 span span:nth-child(5n) { text-shadow: 0 0 0 #83f4f7; }
  h1 span span:nth-child(6n) { text-shadow: 0 0 0 #71f3f6; }
  h1 span span:nth-child(7n) { text-shadow: 0 0 0 #5ff1f5; }
  h1 span span:nth-child(8n) { text-shadow: 0 0 0 #4ef0f4; }


  @keyframes move-text {
    0% {
      bottom: -0.2em;
      opacity: 1;
    }
    
    50% {
      bottom: 0.2em;
    }
  
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
  