.navbar {
    height: 100%;
    width: 60px;
    position: fixed;
    display: flex;
    top: 0;
    flex-direction: column;
    justify-content: space-between;
    z-index: 3;
    min-height: 500px;
    background: #181818;

    @media screen and (max-width: 800px){
        flex-direction:row;
        width: 100%;
        height: 60px;
        top:0;
        left:0;
        min-height: 20px;
    }

    .logo{
        display: block;
        text-align: center;
        position: relative;
        height: 60px;
        width: 60px;
        margin: none;
        padding: 0%;
        cursor: pointer;
    }

    .options{
        display: block;
        text-align: center;
        margin-bottom: 30vh;
        cursor: pointer;
        
        @media screen and (max-width: 800px){
            flex-direction:row;
            margin-bottom: 0;
            width: 70%;
            margin-left: 15%;
        }
        @media screen and (max-width: 500px){
            margin-left: 0;
            width: 80%;
            margin-bottom: 0;
        }

        .option{
            display: block;
            height: 35px;
            width: 35px;
            position: relative;
            text-decoration: none;
            margin: 0vh auto 3vh;
            @media screen and (max-width: 800px){
                margin-right:20px;
                float: left;
                height:60px;
                margin-left: 15px;
            }
            @media screen and (max-width: 500px){
                height: 25px;
                width: 25px;
                margin-left: 4vw;
                margin-right: 4vw;
                margin-top: 5%;
            }
        }
        
        .navbarText{
            color: transparent;
            text-shadow:0 0 0 #42e0f5;
            font-family: 'Roboto', cursive;
            font-weight: 500;
            font-size: 15px;
            text-align: center;
            @media screen and (max-width: 800px){
                font-size: 11px;
                padding: 18px 0;
            }
            @media screen and (max-width: 500px){
                margin-top: 2px;
            }
        }
        .smallerText{
            font-size:13px;
            @media screen and (max-width: 800px){
                font-size: 9.5px;
                width:fit-content;
                text-align:center;
            }
            @media screen and (max-width: 500px){
                font-size: 8px;
                margin-top: 0px;
            }
        }
    }
}

a:link{
    text-decoration: none;
}