$main-font: 'Arial',
sans-serif;
$new-white: rgb(248, 246, 234);
$text-margin: 14px;


// .page-container {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     display: block;
//     perspective: 1000px;
    .card-container {
        height: 400px;
        margin: 0;
        padding: 0;
        top: 20px;
        width: 350px;
        margin-left: 35px;
        // margin-right: 35px;
        position: relative;
        transform-style: preserve-3d;
        box-sizing: border-box;

        // @media screen and (max-width: 767px) {
        //     width: 70vw;
        //     height: 400px; 
        // }

        &.flipped {
            .front {
                transform: rotateY(180deg);
            }
            .back {
                transform: rotateY(0deg);
            }
        }
        .front,
        .back {
            box-sizing: border-box;
            height: 90%;
            width: 100%;
            display: block;
            background: $new-white;
            box-shadow: 10px 10px 5px rgb(78, 99, 77);
            padding: 10px;
            position: absolute;
            
            backface-visibility: hidden;
            transform-style: preserve-3d;
            transition: -webkit-transform ease 600ms;
            transition: transform ease 600ms;
            border-radius: 10px;
            font-weight: 500;
        }
        .front {
            z-index: 2;
            transform: rotateY(0deg);
            /* front tile styles go here! */
        }
        .back {
            background: $new-white;
            transform: rotateY(-180deg);
            padding: 20px;
            font-size: 17px;
            font-weight: 400;
            /* back tile styles go here! */
        }
        .image-container {
            position: relative;
            padding-bottom: 10px;
            margin: auto;
            align-items: center;
            .card-image {
                width: 300px;
                height: 165px;         
                margin-left: auto;
                margin-right: auto;
                
            }
            .title {
                font-size: 25px;
                font-weight: 600;
                position: absolute;
                margin-bottom: $text-margin;
                margin-left: $text-margin;
                bottom: 0;
                left: 0;
                color:#349c9e;
            }
        }
        .main-area {
            margin-left: $text-margin;
            margin-top: $text-margin;
            margin-right: $text-margin;
            
            .blog-post {
                height: 90%;
                
                .date {
                margin-top: 5px;
                margin-bottom: 5px;
                font-size: 20px;
            }
            .read-more {
                font-size: 13px;
                position: relative;
                bottom: 0px;
                text-align: right;
            }
                .blog-content {
                    height: 80%;
                    display: block;
                    margin-top: $text-margin;
                    margin-bottom: $text-margin;
                    // white-space: nowrap;
                    // overflow: hidden;
                }
            }
        }
    }
// }
footer {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;

}



