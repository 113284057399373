
.card1,
.card2,
.card3,
.card4 {
  position: relative;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.card1 {
  // min-width: 400px;
  width: 552px;
  height: 360px;
//   max-width: 40ch;
//   max-height: 40ch;
  margin-bottom: 100px;
  background-image: url(../../assets/pictures/aboutMe.png);
}

.card3 {
  width: 380px;
  height: 120px;
  background-image: url(../../assets/pictures/laptop.png);
  @media screen and (max-width: 1000px){
    visibility: hidden;
}
}

.card2{
  width: 300px;
  height: 130px;
  background-image: url(../../assets/pictures/circuitCartoon.png);
  @media screen and (max-width: 1000px){
    visibility: hidden;
  }
}

.card4{
  width: 450px;
  height: 80px;
  background-image: url(../../assets/pictures/bash.svg);
  @media screen and (max-width: 1000px){
    visibility: hidden;
  }
}

.ParallaxContainer {
  width: 66%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left:8vw;
}
