
body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card-main {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
}

.mywork{
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.hero {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}

.hero-body {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 7.5rem 1.5rem;

    @media screen and (min-width: 980px) {
        padding: 1.5rem 1.5rem;
    }
    @media screen and (max-width: 500px){
        padding-top: 0px;
    }
}

.spring-card-container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
}

.card-row {
    display: flex;
    flex-wrap: wrap;
}

.card-column {
    width: 25%;
    display: flex;
    flex: 1 1 auto;
    padding: 10px;
    @media screen and (max-width: 1100px) {
        width: 50%;
    }
    @media screen and (max-width: 800px) {
        width: 70%;
    }
    @media screen and (max-width: 500px){
        padding-left: 0;
        padding-right: 0;
    }
}

.springCard {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.5s;
    will-change: transform;
    border-radius: 7px;
    height:100%;
}

.card-title {
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    position: absolute;
    margin:auto;
    width: 80%;
}

.card-job {
    position: absolute;
    height: 50px;
    width: 80%;
    margin-top: 50px;
    font-size: 17px;
}

.card-date{
    position: absolute;
    height: 50px;
    margin-top: 100px;
    font-weight: 500;
}

.card-body {

    margin-top: 130px;
    margin-bottom: 27px;
    display: block;
    line-height: 1.5;
    font-size: 15px;
    font-weight: 400;
    height: 100%;
    @media screen and (max-width: 576px) {
        font-size: 15px;
    }
}

.description-list{
    display: inline-block;
    vertical-align: bottom;
    margin: 0px;
    padding: 0px;
}

.image-container {
    margin-top: auto;
    padding-top: 20px;
    padding-bottom: 18px;
    @media screen and (min-width: 576px) {
        padding-bottom: 30px;
    }

    img {
        width: 100%;
    }
}

.image-inner-container {
    width: 100%;
    margin: 0 auto;
    max-width: 170px;
}

.ratio {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.ratio-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
